import React from 'react';

const Heroc = () => {
  return (
    <div className="relative bg-gray-900 py-32 overflow-hidden">
      <div className="absolute inset-0">
      
      </div>
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
        <h1 className="text-5xl md:text-6xl font-bold text-white mb-8">

        Best Casino Hotels to Visit in 2024
          <span className="block text-yellow-500">Australia</span>
        </h1>
        <p className="text-xl text-gray-300 max-w-3xl mx-auto leading-relaxed">
        Casino hotels 2024, where we provide an unforgettable casino resort experience with a harmonious blend of luxury accommodations, gourmet dining, and captivating casino entertainment. Whether you're planning a relaxing weekend getaway, a business trip, or a special celebration, our handpicked casino hotels and resorts ensure a memorable stay with unparalleled service, top-tier casino amenities, and vibrant gaming options. Embrace the excitement and elegance that only a premier casino hotel can offer.
        </p>
      </div>
    </div>
  );
};

export default Heroc;