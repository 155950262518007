import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';

import 'bootstrap/dist/css/bootstrap.css'
import "./App.css"
import ArtHotels from './pages/ArtHotels';
import CapsuleHotels from './pages/CapsuleHotels';
import CasinoHotels from './pages/CasinoHotels';
import About from './pages/About';
import Contat from './pages/Contat';
import CasinoResort from './pages/CasinoResort';





const App=()=> {
  return (
    <Router>
      <div className="App">
       
       
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/art-hotels" element={<ArtHotels />} />
          <Route path="/capsule-hotels" element={<CapsuleHotels />} />
          <Route path="/casino-resorts" element={<CasinoResort />} />
          <Route path="/casino-hotels" element={<CasinoHotels />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/contact-us" element={<Contat />} />
          
          
        </Routes>
      
      </div>
      
    </Router>
  );
}

export default App;