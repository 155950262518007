import React from 'react'

function Hero() {
  return (
<section class="section hero">
        <div class="container">
            <div class="row">
                <div class="col-md-10 mx-auto">
                    <h1 class="top-heading text-center">Use poland-t4 to find Polland's most distinctive hotels.
                    </h1>
                    <p class="lead text-center">Greetings from poland-t4, your one-stop resource for the most distinctive hotels in Polland.
                        We have everything you need, whether you're searching for a luxurious and exciting stay at a casino hotel, an immersive experience in an ice hotel, a convenient and contemporary stay in a capsule hotel, or an artistic haven in the middle of the city. Allow us to lead you through a carefully chosen collection of hotels that will make every visit an unforgettable journey. Explore, evaluate, and reserve your upcoming vacation with the assurance of a memorable experience.
</p>


                </div>
            </div>
        </div>
    </section>
  )
}

export default Hero