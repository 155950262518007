import React from 'react';
import HotelCard from './HotelCardc';

const hotels = [
  {
    id: 1,
    name: 'BRICK AND MORTAR CASINO RESORT',
    description: 'Experience the Epitome of Luxury at Brick and Mortar Casino Resort Step into a world where classic elegance meets modern sophistication at Brick and Mortar Casino Resort. This premier casino hotel offers an unmatched casino resort experience with grand gaming floors featuring over 3,000 state-of-the-art slot machines, 150 thrilling table games, and exclusive VIP rooms for an elevated gaming experience. Enjoy panoramic city views, dine at world-class restaurants, and indulge in the luxurious spa, creating an unforgettable escape. Brick-and-mortar casinos are designed to immerse players in an indulgent atmosphere, featuring dramatic architecture, ornate embellishments, and vibrant themes that captivate the senses and keep guests entertained at every turn.',
    image: '/c/1.png'
  },
  {
    id: 2,
    name: 'EXCALIBUR HOTEL & CASINO RESORT',
    description: 'Step into a world of medieval luxury at Excalibur Hotel & Casino Resort. This iconic castle-themed casino hotel features over 1,200 slot machines, 100 gaming tables, and royal-themed entertainment. Our casino resort offers luxurious accommodations, multiple casino floors, award-winning restaurants, and spectacular shows. Experience the perfect blend of fantasy and fortune in this legendary Las Vegas casino hotel.',
    image: '/c/2.jpg'
  },
  {
    id: 3,
    name: 'WESTGATE LAS VEGAS RESORT & CASINO',
    description: 'Welcome to Westgate Las Vegas Resort & Casino, the ultimate destination where luxury seamlessly blends with world-class entertainment. This premier casino resort boasts an expansive gaming floor featuring the latest slot machines and table games, ensuring an unforgettable casino experience. Indulge in superior dining options, relax in our luxurious rooms with breathtaking views, and rejuvenate at our serene spa. As home to the largest race and sportsbook in Las Vegas, Westgate is the perfect casino hotel for an exciting and lavish getaway.',
    image: '/c/3.jpg'
  },
  {
    id: 4,
    name: 'PULMAN REEF HOTEL CASINO',
    description: 'Discover the pinnacle of luxury and entertainment at Pullman Reef Hotel Casino, where every moment is designed to delight. This premier casino resort features a spectacular rooftop pool with breathtaking views of the reef, premium gaming floors with over 500 state-of-the-art slot machines, and a wide selection of thrilling table games. Indulge in exceptional dining experiences, unwind at our world-class spa, and revel in stunning ocean views from every luxurious room. Pullman Reef Hotel Casino offers the perfect blend of relaxation, excitement, and unforgettable memories.',
    image: '/c/4.jpeg'
  },
  {
    id: 5,
    name: 'JUPITERS GOLD COAST',
    description: 'Experience the royal treatment at Jupiters Gold Coast Casino Resort, a premier destination where luxury and excitement come together. This world-class casino hotel features 1,400 state-of-the-art gaming machines, 70 thrilling table games, and exclusive VIP areas for a truly elevated experience. Indulge in eight exceptional restaurants and bars, unwind at the Azure Spa, and relax in luxurious suites with breathtaking coastal views. Every detail at Jupiters Gold Coast Casino Resort is crafted to deliver an unforgettable casino resort getaway.',
    image: '/c/5.jpeg'
  },
  {
    id: 6,
    name: 'THE VILLE RESORT – CASINO',
    description: 'Welcome to The Ville Resort - Casino, where modern simplicity meets refined luxury. This elegant casino resort features 370 cutting-edge gaming machines, 20 exciting table games, and a serene atmosphere that perfectly balances entertainment and relaxation. Enjoy minimalistic yet stylish accommodations, savor diverse culinary delights at our multiple dining venues, and host unforgettable events in our spectacular spaces. The Ville Resort - Casino is the ideal destination for those seeking a harmonious blend of tranquility and excitement in a premier casino hotel setting.',
    image: '/c/6.jpg'
  },
  {
    id: 7,
    name: 'CROWN MELBOURNE',
    description: 'Immerse yourself in the grandeur of Crown Melbourne Casino Resort, an iconic destination that epitomizes luxury and entertainment. This renowned casino hotel boasts an expansive 220,000-square-foot gaming floor, featuring hundreds of state-of-the-art slot machines and a vast array of table games to suit every preference. Delight in world-class dining experiences, unwind in luxurious accommodations with panoramic city views, and revel in an atmosphere that exudes contemporary elegance. Crown Melbourne Casino Resort sets the standard for premier casino hotel luxury in the vibrant heart of Melbourne.',
    image: '/c/7.jpg'
  }
];

const HotelList = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
      <div className="grid grid-cols-1 gap-12">
        {hotels.map((hotel) => (
          <HotelCard key={hotel.id} {...hotel} />
        ))}
      </div>
    </div>
  );
};

export default HotelList;