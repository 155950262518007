import React from 'react';

const Footerc = () => {
  return (
    <footer className="bg-gray-900 border-t border-gray-800 text-gray-400 py-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
        <p className="text-sm">
          Copyright © {new Date().getFullYear()} 
          <span className="text-yellow-500"> Casino Hotels</span>
          , All Rights Reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footerc;