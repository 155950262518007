import React from 'react';
import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline';

const ContactForm = () => {
  return (
    <div className="bg-gray-900 py-20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-white mb-4">CONTACT US</h2>
          <h3 className="text-2xl text-gray-400">
            If You Have Any Queries,<br />
            Please Feel Free To Contact Us
          </h3>
        </div>
        
        <div className="max-w-3xl mx-auto">
          <div className="flex justify-center space-x-12 mb-12">
            <div className="flex items-center space-x-3 text-gray-300">
              <EnvelopeIcon className="w-6 h-6" />
              <a href="mailto:aus@australia-t4.com.com" className="hover:text-yellow-500 transition-colors">
              </a>
            </div>
            <div className="flex items-center space-x-3 text-gray-300">
              <PhoneIcon className="w-6 h-6" />
              <a href="mailto:visit@australia-t4.com.com" className="hover:text-yellow-500 transition-colors">
              </a>
            </div>
          </div>
          
          <form className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <input
                type="text"
                placeholder="Your Name"
                className="w-full px-6 py-4 bg-gray-800 text-white border border-gray-700 rounded-lg focus:ring-2 focus:ring-yellow-500 focus:border-yellow-500"
              />
              <input
                type="email"
                placeholder="Your Email"
                className="w-full px-6 py-4 bg-gray-800 text-white border border-gray-700 rounded-lg focus:ring-2 focus:ring-yellow-500 focus:border-yellow-500"
              />
            </div>
            <input
              type="text"
              placeholder="Subject"
              className="w-full px-6 py-4 bg-gray-800 text-white border border-gray-700 rounded-lg focus:ring-2 focus:ring-yellow-500 focus:border-yellow-500"
            />
            <textarea
              placeholder="Message"
              rows={6}
              className="w-full px-6 py-4 bg-gray-800 text-white border border-gray-700 rounded-lg focus:ring-2 focus:ring-yellow-500 focus:border-yellow-500"
            ></textarea>
            <button
              type="submit"
              className="w-full bg-yellow-500 text-gray-900 py-4 px-8 rounded-lg font-semibold hover:bg-yellow-400 transition-colors"
            >
              Send Message
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;