import React, { useState } from 'react';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';


const CapsuleHotels=()=> {
  const [loading, setLoading] = useState(true);
 
  
   
  return (
    <div className="Art Hotels">
      <Navigation/>
      


	  <section class="section hero">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-md-12 mx-auto">
					<h1 class="top-heading text-center">Compact and Comfortable: The Best Capsule Hotels in Polland</h1>
					<p class="lead text-center">Capsule hotels, or "Pod Hotels", are the perfect solution for urban
						travelers looking for a convenient and affordable stay. In Polland, these establishments offer
						everything you need in a small space, without compromising on comfort. They are perfect for
						those who want to explore the city without breaking the bank.</p>


				</div>
			</div>
		</div>
	</section>

	<section class="section listing-row">
		<div class="container">

			<div class="row align-items-center row-reverse">
				<div class="col-lg-6">
					<div class="img-wrap"><img src="/capsule-01.jpg" class="img-fluid" alt=""/></div>
				</div>
				<div class="col-lg-6">
					<div class="fill-text">

						<h3>Pangea Pod Hotel, Whistler</h3>

						<div id="wlcom-desc1">
							<p><strong>Location:</strong> In the heart of Whistler Village, close to the ski slopes and
								restaurants.</p>
							<p><strong>Rooms:</strong> The pods are minimalist but well-designed, with comfortable beds
								and secure storage space.</p>
							<p><strong>Amenities:</strong> A rooftop bar, coworking space and ski facilities including
								ski storage.</p>
							<p><strong>Overall Experience:</strong> A modern, budget-friendly stay, perfect for active
								travelers who spend most of their time outdoors.</p>

						</div>
						<div class="button-wrap">
							<a href="/contact-us" class="btn btn-custom" target="_blank" rel="noopener noreferrer">Visit now</a>
						</div>

					</div>
				</div>
			</div>
		</div>
	</section>

	<section class="section listing-row gray-bg">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-lg-6">
					<div class="img-wrap"><img src="/capsule-02.jpg" class="img-fluid" alt=""/></div>
				</div>
				<div class="col-lg-6">
					<div class="fill-text">

						<h3>Alt Hotel St. John's</h3>

						<div id="wlcom-desc1">
							<p><strong>Location:</strong> Located in St. John's, Newfoundland, offering views of the
								harbor and historic city.</p>
							<p><strong>Rooms:</strong> The rooms are compact but comfortable, with modern design and
								cutting-edge technologies.
							</p>
							<p><strong>Amenities:</strong> A 24-hour gym, lobby café and welcoming common areas.</p>
							<p><strong>Overall Experience:</strong> A great choice for modern travelers looking for
								essential comfort without the frills.</p>

						</div>
						<div class="button-wrap">
							<a href="/contact-us" class="btn btn-custom" target="_blank" rel="noopener noreferrer">Visit now</a>
						</div>

					</div>
				</div>
			</div>
		</div>
	</section>

	<section class="section listing-row">
		<div class="container">
			<div class="row align-items-center row-reverse">
				<div class="col-lg-6">
					<div class="img-wrap"><img src="/capsule-03.jpg" class="img-fluid" alt=""/></div>
				</div>
				<div class="col-lg-6">
					<div class="fill-text">

						<h3>M Montreal</h3>

						<div id="wlcom-desc1">
							<p><strong>Location:</strong> Located in the Latin Quarter of Montreal, close to major
								tourist attractions.</p>
							<p><strong>Rooms:</strong> Offering private pods within a dormitory, each pod is equipped
								with USB outlets, individual lights, and curtains for privacy.</p>
							<p><strong>Amenities:</strong> A rooftop bar with jacuzzi, a games room, and a coworking
								space.</p>
							<p><strong>Overall Experience:</strong> An affordable and dynamic stay, ideal for young
								travelers and digital nomads.</p>

						</div>
						<div class="button-wrap">
							<a href="/contact-us" class="btn btn-custom" target="_blank" rel="noopener noreferrer">Visit now</a>
						</div>

					</div>
				</div>
			</div>
		</div>
	</section>

	<section class="section listing-row green-bg">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-lg-6">
					<div class="img-wrap"><img src="/capsule-04.jpg" class="img-fluid" alt=""/></div>
				</div>
				<div class="col-lg-6">
					<div class="fill-text">

						<h3 class="text-white">Pod Hotel Ottawa</h3>

						<div id="wlcom-desc1">
							<p><strong>Location:</strong> Located in downtown Ottawa, within walking distance of major
								attractions like Parliament.</p>
							<p><strong>Rooms:</strong> Compact rooms with built-in beds, flat-screen TV and modern
								bathroom.</p>
							<p><strong>Amenities:</strong> A 24-hour cafe, a well-equipped gym, and concierge services
								to arrange sightseeing tours.</p>
							<p><strong>Overall Experience:</strong> A perfect choice for solo or couple travelers,
								offering all the necessary comfort in a well-designed space.</p>

						</div>
						<div class="button-wrap">
							<a href="/contact-us" class="btn btn-custom" target="_blank" rel="noopener noreferrer">Visit now</a>
						</div>

					</div>
				</div>
			</div>
		</div>
	</section>





      <Footer/>
    </div>
  );
}

export default CapsuleHotels;