import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
const Footer=()=> {
  const [cookieIsAccepted,setCookieIsAccepted]=useState(true)
  const [ageIsAccepted,setAgeIsAccepted]=useState(true)

  useEffect(()=>{
const value=localStorage.getItem("cookie")
if(value)
{
  setCookieIsAccepted(false)
}
  },[])

const acceptCookie=()=>{
  setCookieIsAccepted(false)
  localStorage.setItem("cookie",true)
}

  return (
    <>
    <footer class="footer">
    <div class="container">
        <div class="row align-items-center">

            <div class="col-md-6 copyright">
                <a href="/">
                    <h2 class="m-0">
                        poland-t4
                    </h2>
                </a>
            </div>
            <div class="col-md-6">
                <div class="footer-links">
                    <a href="/privacy-policy">Privacy Policy</a> |
                    <a href="/terms-and-conditions">Terms and Conditions</a>
                </div>

            </div>
        </div>


    </div>
</footer>
<div class="after-footer">
        <div class="container">
            <div class="row gx-lg-5">
                <div class="col-12">
                    <div class="text-center">Copyright © 2024 poland-t4 | All rights reserved</div>

                    <div class="social-links">
                        <a href="https://twitter.com/?lang=en" target="_blank"><i class="fa fa-twitter"></i></a>
                        <a href="https://www.facebook.com/" target="_blank"><i class="fa fa-facebook-f"></i></a>
                        <a href="https://www.instagram.com/?hl=en" target="_blank"><i class="fa fa-instagram"></i> </a>
                        <a href="https://www.youtube.com/" target="_blank"><i class="fa fa-youtube"></i></a>
                    </div>



                </div>
            </div>
        </div>
   

        {
    cookieIsAccepted &&(
        <div id="newcookies">
        <div class="container">
            <div class="pdb-3">
                <div>
                    <p>Our website uses cookies to store and access your preferences repeatedly to give you the most
                        relevant experience. By clicking "OK", you agree to the use of all cookies.</p>
                </div>
                <button onClick={e=>acceptCookie(true)} type="button" class="btn modalBtn" data-dismiss="modal">OK</button>
            </div>
        </div>
    </div>
    )
  }
       
    </div>
    </>

  );
}

export default Footer;