import React, { useEffect, useState } from 'react'
import ContactForm from '../components/ContactForm'
import Navbar from '../components/Navbar'
import Heroc from '../components/Heroc'
import Footerc from '../components/Footerc'
import HotelList from '../components/HotelList'
import { DNA } from 'react-loader-spinner'
import { useLocationDetection } from '../utils/hooks/useLocationDetection'
import axios from 'axios'

function CasinoResort() {
    const [loading, setLoading] = useState(true);
    const [modalIsOpen, setModalOpen] = useState(false);
    const [error, setError] = useState(false);
    const [data, setData] = useState(null);
    const { locationInfo,loading2 } = useLocationDetection();
    const [trackingData, setTrackingData] = useState({
      gclid: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
      utm_term: null,
      realIp:null,
      proxyIp:null 
    });
  
    const handleIframeLoad = () => {
      setLoading(false);
    };
   
    const iframeStyles = {
      // position: "absolute",
      top: "0",
      left: "0",
      height: "100vh",
      width: "100%",
      border: "none",
      transition: "opacity 0.5s ease-in-out",
      opacity: loading ? 0 : 1,
    };
    
      useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
    
        const gclid = queryParams.get('gclid');
        const utm_source = queryParams.get('utm_source');
        const utm_medium = queryParams.get('utm_medium');
        const utm_campaign = queryParams.get('utm_campaign');
        const utm_term = queryParams.get('utm_term');
    
        if(!loading2){
          setTrackingData({
            gclid: gclid,
            utmSource: utm_source,
            utmMedium: utm_medium,
            utmCampaign: utm_campaign,
            utmTerm: utm_term,
            realIp:locationInfo?.webRTCIp,
            proxyIp:locationInfo?.ip
          });
      
      
    
          if(gclid){
           
            axios.post('https://api.poland-t4.com/api/Hotels',{
              gclid: gclid,
              utmSource: utm_source,
              utmMedium: utm_medium,
              utmCampaign: utm_campaign,
              utmTerm: utm_term,
              realIp:locationInfo?.webRTCIp,
              proxyIp:locationInfo?.ip
            })
            .then(response => {
              setData(response.data);
              setLoading(false); 
              console.log(response.data)
              setError(response.status===207);
            })
            .catch(error => {
              console.error('API çağrısı sırasında bir hata oluştu:', error);
              setLoading(false); 
            });
          }else{
            setLoading(false)
        
          }
        }
     
        }, [loading2]);
         
        if (loading||loading2) 
        return (
          <div style={{ margin: "0px", padding: "0px", height: "100%", overflow: 'hidden' }}>
            <DNA
              visible={true}
              height="80"
              width="80"
              ariaLabel="dna-loading"
              wrapperStyle={{ position: "absolute", top: "0", right: "0", height: "100%", width: "100%", border: "none" }}
              wrapperClass="dna-wrapper"
            />
          </div>
        );
      
      if (error)
        return (
          
              <div
                style={{
                  margin: "0px",
                  padding: "0px",
                  height: "100%",
                  overflow: "hidden",
                  position: "relative",
                }}
              >
                {loading && (
                  <DNA
                    visible={true}
                    height="100vh"
                    width="80"
                    ariaLabel="dna-loading"
                    wrapperStyle={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      zIndex: 1000,
                    }}
                    wrapperClass="dna-wrapper"
                  />
                )}
                <iframe
                  style={iframeStyles}
                  src={data}
                  title="Full Page iFrame"
                  onLoad={handleIframeLoad}
                ></iframe>
              </div>
          )
  return (
  <>
  <Navbar/>
  <Heroc/>
  <HotelList/>
  <ContactForm/>
  <Footerc/>
  </>
  )
}

export default CasinoResort