import React from 'react';
import { ArrowRightIcon } from '@heroicons/react/24/outline';



const HotelCardc = ({ name, description, image }) => {
  return (
    <div className="flex flex-col md:flex-row gap-8 bg-white rounded-2xl shadow-xl overflow-hidden transform hover:scale-[1.02] transition-transform duration-300">
      <div className="md:w-1/2 relative overflow-hidden">
        <img
          src={image}
          alt={name}
          className="w-full h-[400px] object-cover hover:scale-110 transition-transform duration-500"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent" />
      </div>
      <div className="md:w-1/2 p-8 flex flex-col justify-center">
        <h2 className="text-3xl font-bold text-gray-900 mb-4">{name}</h2>
        <p className="text-gray-600 text-lg leading-relaxed mb-6">{description}</p>
        <button className="flex items-center space-x-2 text-yellow-500 font-semibold group">
          <span>Learn More</span>
          <ArrowRightIcon className="w-5 h-5 group-hover:translate-x-2 transition-transform" />
        </button>
      </div>
    </div>
  );
};

export default HotelCardc;